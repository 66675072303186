import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import NavBar from '../NavBar/Navbar'
import Footer from '../Footer/Footer'

const Cv = () => {
    return (
        <>
            <Header />
            <NavBar />
            <Outlet/>
            <Footer/>
        </>

    )
}

export default Cv