import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../../App.css';
import Footer from '../Footer/Footer';
import Header from '../Header/Header'
import IntroductionPage from '../IntroductionPage/IntroductionPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import {IconContext} from 'react-icons'
import WelcomePage from '../WelcomePage/WelcomePage';
import NavBar from '../NavBar/Navbar';
import FormationsPage from '../FormationsPage/FormationsPage';
import WorkExperiencesPage from '../WorkExperiencesPage/WorkExperiencesPage';
import WorkSkillsPage from '../WorkSkillsPage/WorkSkillsPage';
import PortfolioPage from '../PortfolioPage/PortfolioPage';
import ContactsPage from '../ContactsPage/ContactsPage';
import Cv from '../Cv/Cv';

function App() {
    return (
        
        
        <Router>
            <IconContext.Provider value={{style: {verticalAlign: 'middle'}}}>
                
                
                <Routes>
                    <Route path="/" element={<WelcomePage />} />
                    <Route element={<Cv/>} >                                         
                        <Route path="accueil" element={<IntroductionPage />} />
                        <Route path="formations" element={<FormationsPage />} />
                        <Route path="experiences" element={<WorkExperiencesPage />} />
                        <Route path="competences" element={<WorkSkillsPage />} />
                        <Route path="portfolio" element={<PortfolioPage />} />
                        <Route path="contacts" element={<ContactsPage />} />
                        <Route path='*' element={<ErrorPage />} />
                    </Route>
                    
                </Routes>
                
                        
            </IconContext.Provider>
        </Router>
        
    );
}

export default App;
