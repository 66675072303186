import React , {useRef, useEffect, useState, Fragment} from 'react'
import Accordion from'../Accordion/Accordion'
import { accordionData } from './contentFormation';



const IntroductionPage = () => {

    return (
        <main className='presentation-container'>

            <h1>Formations et diplômes</h1>
            
            <div className='presentation-content'>
                {accordionData.map(({ title, content }) => (
                    <Accordion title={title} content={content} />
                ))}
                
            </div>
        </main>
    )
}

export default IntroductionPage