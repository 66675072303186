import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './squareButton.css';



const SquareButton = (props) => {

    

    const [dataBtn, setDataBtn] = useState(props)
    const [labelBtn, setLabelBtn] = useState(false)

    const showLabel = () => {
        setLabelBtn(true)
        dataBtn.modifyLabel(dataBtn.subTextBtn)
    }

    const hideLabel = () => {
        setLabelBtn(false)
        dataBtn.modifyLabel('')
    }


    const displayLabel = labelBtn && (
        <p>{dataBtn.subTextBtn}</p>
        
    )


    return (
        
        <div className='squareBtnContainer'>
            <Link to={dataBtn.linkBtn} className="text-link" >
                <div className='squareButton' onMouseOver={showLabel} onMouseOut={hideLabel}>{dataBtn.iconBtn}</div>
                
            </Link>
            
        </div>
        
        
    )
}

export default SquareButton

//style={{ textDecoration: 'none' }}