import React , {useRef, useEffect, useState, Fragment} from 'react'
import { useNavigate } from 'react-router-dom';
import {gsap} from "gsap"
import Letter from '../Letter/Letter';
import './welcomePage.css'
import Logo from '../Logo/Logo';
import Prez3D from '../Prez3D/Prez3D';

const Welcome = () => {

    const letterC = useRef()
    const letterV = useRef()
    const artdesignerWord = useRef()
    const infographisteWord = useRef()
    const webdesignerWord = useRef()
    const webdeveloperWord = useRef()
    const cyrilleWord = useRef()
    const christmannWord = useRef()
    const animationContainer = useRef()
    const animationIntroContainer = useRef()
    const miwi = window.innerWidth/2
    const mihe = window.innerHeight/2

    const navigate = useNavigate();

    const TL = gsap.timeline();

    useEffect (() => {

        TL
        .to(cyrilleWord.current, {x: miwi, xPercent:-50, opacity:1, duration:0.5 , delay:0.5})
        .to(cyrilleWord.current, {opacity: 0, y: -100,xPercent: -50, rotation: -15, duration:0.5 , delay:0.5})

        .to(christmannWord.current, {x: -miwi, xPercent:50, opacity:1, duration:0.5 , delay:0.5})
        .to(christmannWord.current, {opacity: 0, y: -100,xPercent: 50, rotation: -15, duration:0.5 , delay:0.5})

        .to(artdesignerWord.current, {y: mihe, xPercent:0, opacity:1, duration:0.5 , delay:0.5})
        .to(artdesignerWord.current, {opacity: 0, rotation: -15, duration:0.5 , delay:0.5})

        .to(infographisteWord.current, {y: -mihe, yPercent:50, opacity:1, duration:0.5 , delay:0.5})
        .to(infographisteWord.current, {opacity: 0, rotation: -15, duration:0.5 , delay:0.5})
    
        .to(webdesignerWord.current, {y: mihe, xPercent:0, opacity:1, duration:0.5 , delay:0.5})
        .to(webdesignerWord.current, {opacity: 0, rotation: -15, duration:0.5 , delay:0.5})

        .to(webdeveloperWord.current, {y: -mihe, yPercent:50, opacity:1, duration:0.5 , delay:0.5})
        .to(webdeveloperWord.current, {opacity: 0, rotation: -15, duration:0.5 , delay:0.5})

        .to(animationIntroContainer.current, {opacity: 0,   duration:0.5})
        .to(animationContainer.current, {opacity: 1, y:0,  duration:0.5}, '-=0.85')

        .fromTo(letterC.current, {
            y: "-10000",
            opacity: 0
            }, {
            y: "0",
            opacity: 1,
            rotation: "1080",
            duration: 1,
            stagger: 0.2
        })

        .fromTo(letterV.current, {
            y: "-10000",
            opacity: 0
            }, {
            y: "0",
            opacity: 1,
            rotation: "1080",
            duration: 1,
            stagger: 0.2
        })

        

        // setTimeout(() => {
        //     TL.play()
        // //     navigate("/accueil")
        // }, 1000)
        
    }, []);
    

    return (
        <React.Fragment>
            <div className='animation-intro-container' ref={animationIntroContainer}>

                <div className='animation-word-artdesigner' ref={artdesignerWord}>
                        Art Designer
                </div>
                
                <div className='animation-word-infographiste' ref={infographisteWord}>
                        Infographiste PAO
                </div>
                
                <div className='animation-word-webdesigner' ref={webdesignerWord}>
                        Web Designer
                </div>

                <div className='animation-word-webdeveloper' ref={webdeveloperWord}>
                        Développeur Web <br/>Full Stack
                </div>

                <div className='animation-word-cyrille' ref={cyrilleWord}>
                        Cyrille
                </div>

                <div className='animation-word-christmann' ref={christmannWord}>
                        Christmann
                </div>

            </div>

            <div className='animation-container' ref={animationContainer}>
                
                <Logo className="logo"/>
                
                
                <div className='animation-cv'>
                    <Letter contentLetter={'C'} ref={letterC} />
                    <Letter contentLetter={'V'} ref={letterV} />

                </div>
                

                <Prez3D/>

                <div className='devWebFullstackText'>Développeur Web Fullstack</div>
                <div className='ccText'>Cyrille Christmann</div>
            </div>
        </React.Fragment>
    )
}

export default Welcome