import React from 'react'
import ContactForm from "./ContactForm";
import './contactsPage.css'

const ContactsPage = () => {
    return (
        <main className='presentation-container'>

            <h1>Contacts</h1>
            
            

            <div className='presentation-content-contact'>

                <ContactForm />


                
                
            </div>

            
        </main>
    )
}

export default ContactsPage