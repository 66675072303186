import { Link } from "react-router-dom";
import './contentFormation.css';

export const accordionData = [
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 2015 - Aujoud'hui:</span> Autoformations suivies et réussites en ligne sur des plateformes MOOC et Digital Learning, livres et magazines</div></>,
        content: 
        <>
            <div className="contentFormation">

                <div className="subtitle"> Formations diverses</div>
                - React de A à Z (fromscratch.podia.com)<br/>
                - Symfony 5 : Le guide complet (Lior Chamla)<br/>
                - Flutter révolution (Driss As)<br/>
                - Git Starter (Mike Codeur)<br/>
                - Tester un controller. WebTestCase de symfony 5 (Grafikart)<br/>


                <div className="subtitle"> Formations sur Udemy</div>
                - Créer une app FullStack TypeScript avec Angular et NestJS (Code Concept)<br/>
                - Développer Votre Première Application avec Angular 2022 (Simon Dieny)<br/>
                - Développez en Full Stack avec Spring Boot et Angular (Nadhem BELHADJ)<br/>
                - Devenez développeur Java (Jean-Philippe Ehret)<br/>
                - Java et les bases de données avec JDBC, Hibernate et JPA (Jean-Philippe Ehret)<br/>
                - Bien débuter avec Spring et Spring Boot pour Java (Jean-Philippe Ehret)<br/>
                - Java EE : Devenez développeur d'applications Web Java  (Jean-Philippe Ehret)<br/>
                - React JS pour tous - L'ultime formation (Donkey Geek)<br/>
                - React de A à Z (Hooks, Redux, Contexte inclus) (Enzo Ustariz)<br/>
                - Déployer son site Symphony 5 en production (Mikael Houdoux)<br/>
                - Node.js le Guide Ultime (+Express, React, MongoDB, JWT) (Antho Welc)<br/>
                - React - Maitriser le rendu côté serveur avec NextJs (Enoch Ndicka)<br/>                
                - Fullstack React 17, GraphQl, Apollo, Google Sign & Paypal<br/>
                - Bootstrap 5: La formation Ultime (Louis Nicolas Leuillet)<br/>
                - Flexbox CSS - Le guide complet par la pratique (Axel Paris)<br/>
                - Les bases indispensables de la programmation : Algorithmique (Mathieu Gaston)<br/>
                - La formation complète Python (Thibault Houdon)<br/>
                - Python : les interfaces graphiques avec PySide<br/>
                - Apprendre Node.js et créer une API REST de A à Z ! (Bryan P.)<br/>
                - Apprendre Symfony 5 par la création d’un site e-commerce<br/>
                - Flutter 2 et Dart : Créez des applications pour IOS et ANDROID (Mathieu Passerel)<br/>
                - Maîtrisez Css GRID ! (Enzo Ustariz)<br/>


                <div className="subtitle"> Formation sur OpenclassRoom:</div>
                - Développeur Web PHP / Symfony (OC/M.Nebra)<br/>
                - Développez des sites PHP professionnels (OC/M.Nebra)<br/>
                - Intégrateur Web (OC/M.Nebra)<br/>
                - Lancer son propre site Web (OC/M.Nebra)<br/>
                - Débutez l'analyse logicielle avec UML (école ESGI/C. Roels)<br/>
                - Comprendre le Web (OC/M. Nebra)<br/>
                - Apprenez à créer votre site avec html5 et CSS (école EFREI/M. Nebra)<br/>
                - Prenez en main Bootstrap (Développeur retraité/M. Chavelli)<br/>
                - Les clés pour réussir son référencement web (Tamento/L. Galichet)<br/>
                - Concevez votre site web avec PHP et Mysql (école ESGI/M. Nebra)<br/>
                - Administrez vos bases de données avec Mysql (OC/C. Gibraumont)<br/>
                - Gérer son code avec Git et Github (OC/M. Gauthier)<br/>
                - Programmez en orienté objet en PHP(OC/V. Thuillier)<br/>
                - Évoluez vers une architecture PHP professionnelle(professeur agrégé à Polytech Lyon/B. Pesquet)<br/>
                - Développez votre site web avec le framework Symfony 2 (SensioLabs F. Potencier/A. Bacco)<br/>

                <div className="subtitle"> Livres lus</div>
                - Linux administration (JF Bouchaudy, G. Goubet)<br/>
                - Php 5 best practices (G. Ponçon)<br/>
                - L’orienté objet (H. Bersini)<br/>
                - Modélisation conceptuelle de données (Patrick Bergougnoux)<br/>
                - Symfony 2 (Bilal Amarni)<br/>
                - En route pour Symfony 5 (Fabien Potencier)<br/>

                <div className="subtitle"> Magazines très souvent lus</div>
                - Web Design<br/>
                - Linux magazine, Linux pratique<br/>
                - Misc<br/>
                - Coding<br/>
                - Programmez !<br/>

                <div className="subtitle"> Autres</div>
                - Nombreuses vidéos Youtube sur le developpement web, veille Technos, Nocode <br/>
                - etc ... Mais je vais m'arrêter là ;) <br/>


            </div>
        </>
        },
    
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 2020 - 2021:</span> Licence Professionnelle (bac + 3). Concepteur Développeur d'Application. RNCP: 31678. Web développeur FullStack. (CCi de Strasbourg) <span className="graduate">Diplomé</span></div></>,
        content: 
        <>
            <div className="contentFormation">
            - HTML CSS JAVASCRIPT PHP POO SYMFONY SQL GIT MERISE UML Méthode agile (Scrum) DEVOPS. <br/>
            - Projet de formation: PassionTalk (application de discussions et de mise en relation autour d'une passion)<br/>
            - Stage en entreprise conception et développement d'une plateforme de vod (Phoenix Taekwondo Strasbourg VOD)<br/> 
            </div>
        </>
        },
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 2002 - 2005:</span> Intégrateur, Webdesigner, Webmasteur. Université de Strasbourg </div></>,
        content: 
        <>
            <div className="contentFormation">
                - Autodidacte et formé au Pôle Multimédia par M. Bruno Dupuis - HTML CSS ASP FLASH <br/> 
                - Conception sites et d'applications web

            </div>
        </>
        },
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 1998 - 1999:</span> Service militaire Gendarmerie Nationale (CIGA Auxerre, Brigade Territoriale de Woerth) </div></>,
        content: 
        <>
            <div className="contentFormation">
                - Gendarme Auxiliaire Brigade territoriale de Woerth - Compagnie de Wissembourg. <br/> 
                - Formé aux Missions de Gendarmerie, port d'armes.<br/>
                - Stage de technicien de scène de crimes à la compagnie de Haguenau (section de recherche).

            </div>
        </>
        },
        {
        title: <><div className="title"> <span className="period"> 1996 - 1998:</span> BTS Industries Graphiques option Communication Graphique, Lycée Gutenberg (Strasbourg) <span className="graduate">Diplomé</span></div></>,
        content: <><div className="contentFormation"> - Infographiste/Opérateur PAO - Chaîne Graphique (pré-presse) <br/> 
        - Projet de fin de cursus: Produits promotionnels des BTS de l'académie de Strasbourg (Packaging + Fiches Imprimées + CD Rom intéractif)</div></>
        },
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 1992-1996:</span> Baccalauréat STI Electronique, Lycée Couffignal (Strasbourg) <span className="graduate">Diplomé</span></div></>,
        content: 
        <>
            <div className="contentFormation">
                - Seconde Technique des systèmes automatisés (TSA) <br/> 
                - Seconde productique, Electronique, Electrotechnique<br/>
                - Spécialisation en première en Electronique<br/>
                - Projet de fin de cursus: Système d'alimentation collier sans fil pour bovins, capteur box

            </div>
        </>
        },
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 1992-1996:</span> Sport Etude Hand Ball, Lycée Kleber (Strasbourg) </div></>,
        content: 
        <>
            <div className="contentFormation">
                - Etude au Lycée Couffignal, Sport Lycée Kléber <br/> 
                - Championnat de France UNSS<br/>
            </div>
        </>
        }
        
        // {
        // title: 'test',
        // content: <><div className="contentFormation"> <Link to={'/accueil'} className="linkFormation">toto</Link> toto <br/> - tata</div> <div>top</div></>
        // },
        
];
