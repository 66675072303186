import React from 'react'
import SocialNetworks from '../SocialNetworks'

const Footer = () => {
    return (
        <footer>
            <div className='footer-container'>
                <p> &copy; Cyrille CHRISTMANN 2024 </p>
                
                
                
                
            </div>
        </footer>
    )
}

export default Footer