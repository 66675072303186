import React from 'react'
import photoCC from './assets/img/cych.png'
import './photoCC.css'

const PhotoCC = () => {
    return (
        <img src={photoCC} alt="photo Cyrille Christmann" className='photoCC'/>
    )
}

export default PhotoCC