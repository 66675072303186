import React from 'react'



const Header = () => {
    return (
        <header>
            
                <h1 className='header-title'>  <a href="/" > Cyrille CHRISTMANN - CV </a></h1>
            
        </header>
    )
}

export default Header