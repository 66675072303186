import React , {useState} from "react";
import SquareButton from "../SquareButton/SquareButton";
import { FaUserGraduate} from 'react-icons/fa';
import { MdWork} from 'react-icons/md';
import { GiNetworkBars} from 'react-icons/gi';
import { BiMailSend} from 'react-icons/bi';
import { BsFillFileEarmarkCodeFill} from 'react-icons/bs';
import { AiFillHome} from 'react-icons/ai';



const Navigation = () => {

    const [label, setLabel] = useState (null);

    const labelPage = label && (<div className="labelNav"> {label}</div>)

    const modifyLabel = (label) => {
        setLabel(label)
    }

    return (
        <div className="navigation">
            <div className="menu-container">
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Accueil" linkBtn="/accueil" iconBtn={<AiFillHome size='70%' />} />
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Formations" linkBtn="/formations" iconBtn={<FaUserGraduate size='70%' />} />
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Expériences" linkBtn="/experiences" iconBtn={<MdWork size='70%' />} />
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Compétences" linkBtn="/competences" iconBtn={<GiNetworkBars size='70%' />} />
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Portfolio" linkBtn="/portfolio" iconBtn={<BsFillFileEarmarkCodeFill size='70%' />} />
                <SquareButton modifyLabel={modifyLabel} subTextBtn="Contact" linkBtn="/contacts" iconBtn={<BiMailSend size='70%' />} />
            </div>
            <div className="labelNav">{labelPage}</div>
        </div>
        
    );
};

export default Navigation;


