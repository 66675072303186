import { Link } from "react-router-dom";
import './contentWorkExperiences.css';

export const accordionData = [
    {
        title: 
        <>
            <div className="title"> 
                <span className="period"> 1994 - 2024:</span> Sociétés de la région, Employé technicien Polyvalent</div></>,
        content: 
        <>
            <div className="contentFormation">

                <div className="subtitle"> Poste:</div>
                Employé libres services<br/>

                <div className="subtitle"> Situation:</div>
                Différents emplois intérimaires et cdd.<br/>
                
                <div className="subtitle"> Enjeux:</div>
                Rendre services<br/>
                
                <div className="subtitle">Missions:</div>
                - Tri colis, préparations de commandes, chargement de camions (Mondial Relay, Jet Est)<br/>
                - Déménagements Internationnaux, ripages (Déménagement Stoeckel)<br/>
                - Déménagement siege social Banque Populaire (Silatec)<br/>
                - Déménagement siege social (Maison Créa) <br/>
                - Manoeuvre, assistant scaphandrier (Silatec)<br/>
                - Manoeuvre, agent de fabrication (Brasserie Fischer)<br/>
                - Manoeuvre, poses cloisons douche, poses support tv, montage lit, Hotel first classe (Sigmatech) <br/>
                - Nettoyeur de cage de souris (IGBMC)  <br/>
                - Opérateurs de fabrications de poutres, linteau, dalles en béton armées (Planchers Durandal)<br/>
                - Alimenteur de machines de conditionnements (Data Mailing) <br/>
                - Manutentionnaire sur chantier, ports et mise en place de cloison aluminium (Batimpro)<br/>
                - Manutentionnaire, ports et mise en place de fenêtres (Menuiserie Cléments)<br/>
                - Employé libre Service tout rayons, rayonnage, balisage, relation client (Auchan, Super U)<br/>
                - Agent de fabrication, soutireur, pasteurisateur (Orangina France)<br/>
                - Manoeuvre, montage de petites vis ( Construction Electriques Fels)<br/>
                
                <div className="subtitle"> Outils employés:</div>
                De nombreux outils<br/>

                <div className="subtitle">Résultats:</div>
                Renforcer les sociétés lors de l'augmentation de la charge de travaux<br/>

                <div className="subtitle">Evaluations:</div>
                De nombreuses expériences professionnelles qui m'ont apporté plusieurs compétences techniques et savoir-faire.   <br/><br/>

            </div>
        </>
    },{
        title: 
        <>
            <div className="title"> 
                <span className="period"> 2021:</span> PHOENIX TAEKWONDO STRASBOURG - Stage en entreprise - Développeur Web Fullstack <span className="graduate">Editions Web App</span></div></>,
        content: 
        <>
            <div className="contentFormation">

                <div className="subtitle"> Poste:</div>
                Concepteur Développeur FULLSTACK d'application Web<br/>

                <div className="subtitle"> Situation:</div>
                Concevoir et développer une plateforme de vidéo à la demande pour le club PHOENIX TAEKWONDO (avec abonnements pour les vidéos privées).<br/>

                
                <div className="subtitle"> Enjeux:</div>
                Finir la plateforme en 3 mois. Respecter un budget bas de fonctionnement pour le club et coût bas pour les adhérents. <br/>

                <div className="subtitle"> Difficultée:</div>
                Travailler en complète autonomie (Carte blanche pour le projet en partant de zéro).<br/>
                
                <div className="subtitle">Missions:</div>
                    -	Maquetter l’application<br/>
                    -	Développer l’interface utilisateur de type desktop<br/>
                    -	Développer les composants d’accès aux données<br/>
                    -	Développer la partie front end de l’interface utilisateur web<br/>
                    -	Développer la partie backend de l’interface utilisateur web<br/>
                    -	Concevoir la base de données de la plateforme<br/>
                    -	Mettre en place la base de données de la plateforme<br/>
                    -	Développer les composants dans le langage d’une base de données<br/>
                    -	Collaborer à la gestion d’un projet informatique et à l’organisation de l’environnement de développement<br/>
                    -	Concevoir l’application de VOD<br/>
                    -	Développer les composants métiers<br/>
                    -	Construire l’application organisées en couches<br/>
                    -	Préparer et exécuter les plans de tests de l’application<br/>
                    -	Préparer et exécuter le déploiement de l’application<br/>
                    -	Intégrer la sécurisation de l’application à tous les niveaux<br/>

                <div className="subtitle"> Langages et outils employés:</div>
                Html, Css, Javascript, Bootstrap, Php, POO, MVC, Symfony, Doctrine, Twig, Mysql, Git, Git Hub, Webpack encore, Vuejs, Mercure, Axios, Phpunit, JMerise, Api Stripe, Cpanel (lws), Youtube, Viméo,  api Mailjet. NPM, Composer<br/>

                <div className="subtitle">Résultats:</div>
                Challenge relevé, délais tenus, budget tenu. La plateforme est en ligne.<br/>

                <div className="subtitle">Evaluations:</div>
                Mon souhait et chalenge pour ce stage était donc de développer entièrement et en complète autonomie une application web (de sa conception à sa mise en production). Ce choix était une Plateforme de vidéo à la demande.
                La tâche fut assez facile au début pour la conception de l’ergonomie et du design de l’application. Je pouvais à ce moment-là m’appuyer sur l’expérience des métiers de Webdesigner - Intégrateur / Opérateur PAO que j’eusse eu pratiquer dans les années 2000 durant 3 ans.
                Les premières difficultés sont arrivées avec la conception et la modélisation de la base de données. N’ayant pas une grande expérience en la matière je me suis aidés des quelques tutoriaux passés sur le site de formation OpenClassRoom, le livre modélisation conceptuelle de données de Patrick Bergougnoux, et surtout sur les cours de bases de données et d’UML de la formation. Après quelques réflexions et suivis de méthodes j’ai pu concevoir la base de données.
                Une deuxième difficulté majeure s’est posée sur comment développer la partie abonnement de l’application. Pour résoudre cette problématique je me suis aidé de ressources trouvées sur le web. Je me suis particulièrement attardé pendant les trois premières semaines de ce stage sur comment faire des applications E-boutique avec le framework Symfony. Petit à petit, les solutions s’offrirent à moi. Notamment pour l’utilisation de l’api de Stripe et de Mailjet. La section abonnements est en place.
                Une dernière difficulté était de développer la partie catalogue. De la même façon que pour la partie abonnement, mêlant cette fois ci l’ajax au back end Symfony, recherche après recherche sur internet j’ai réussi à développer cette fonctionnalité.<br/><br/>
                Je pense avoir mener à bien, et fier d’avoir pu mettre en ligne, le projet que l’on m’a proposé en respectant les spécificités du référentiel imposé. Et cela, tout en respectant la demande de simplicité d’utilisation et le petit budget de fonctionnement du client. Il est à l’image de mon expérience débutante dans le développement web.
Cette plateforme reste perfectible sur de nombreux points et fonctionnalités comme par exemples des inscriptions/connexions avec les réseaux sociaux, des favoris, le partage des vidéos.<br/>

            </div>
        </>
        },
        {
            title: 
            <>
                <div className="title"> 
                    <span className="period"> 2020 - 2021:</span> Formation à la Chambre de Commerce et d'Industries à Strasbourg - Concepteur Développeur D'application <span className="graduate">Editions Web App</span></div></>,
            content: 
            <>
                <div className="contentFormation">
    
                    <div className="subtitle"> Poste:</div>
                    Concepteur Développeur FULLSTACK d'application Web<br/>
    
                    <div className="subtitle"> Situation:</div>
                    
                    Apprentissage du métier de Concepteur Développeur d'Application par la conception et le développement d'une application web dans une équipe de trois personnes<br/>
                    
                    <div className="subtitle"> Enjeux:</div>
                    
                    Projet de formation. Carte blanche sur le thème de l'application, équipes imposées. Conception et Développement d'une application de discussions et mise en relation autour d'une passion (PassionTalk)<br/>
                    
    
                    <div className="subtitle">Difficultée:</div>
                    Travailler en équipe sur de nouvelles technologies<br/>
                    
    
                    <div className="subtitle">Missions:</div>
                        -	Maquetter l’application<br/>
                        -	Développer l’interface utilisateur de type desktop<br/>
                        -	Développer les composants d’accès aux données<br/>
                        -	Développer la partie front end de l’interface utilisateur web<br/>
                        -	Développer la partie backend de l’interface utilisateur web<br/>
                        -	Concevoir la base de données de la plateforme<br/>
                        -	Mettre en place la base de données de la plateforme<br/>
                        -	Développer les composants dans le langage d’une base de données<br/>
                        -	Collaborer à la gestion d’un projet informatique et à l’organisation de l’environnement de développement<br/>
                        -	Concevoir l’application de VOD<br/>
                        -	Développer les composants métiers<br/>
                        -	Construire l’application organisées en couches<br/>
                        -	Préparer et exécuter les plans de tests de l’application<br/>
                        -	Préparer et exécuter le déploiement de l’application<br/>
                        -	Intégrer la sécurisation de l’application à tous les niveaux<br/>
    
                    <div className="subtitle"> Langages et outils employés:</div>
                    Html, Css, Javascript, Bootstrap, Php,POO, MVC, Symfony, Doctrine, Twig, Mysql, Git, Git Hub, Webpack encore, Uml, Azure devops (méthode agile scrum), Phpunit, JMerise, Adobe XD (prototypage) Photoshop, Serveur linux NPM, Composer<br/>
    
                    <div className="subtitle">Résultats:</div>
                    Projet de formation finalisé et déployé<br/>
    
                    <div className="subtitle">Evaluations:</div>                 
                    Cette expérience professionnelles couvre la totalité des compétences nécessaires au métier de développeur d’application web. elle se conforme aux exigences des technologies employées sur le marché. Plus précisément j’ai beaucoup aimé l'apprentissage des méthodes agiles. Nous utilisions la méthode agile SCRUM avec l’environnement Azure DEVOPS de chez Microsoft pour l’application celle-ci. Pour ma part ce fut une totale découverte. 
                    Le développement de cette application est la mise en application des cours enseignés en parallèle.
                    Pour ma part il n’a pas été toujours aisé de travailler en équipe pour la réalisation de ce projet. Mais dans l’ensemble celui a été conçu et développé avec succès.
                    La principale difficulté rencontrée était le manque de connaissances, d’échanges et d’entraides en présentiel. Cependant nous pallions ce manque par des réunions de travail le soir et le week end en distanciel grâce à l’application Teams de Microsoft et Git.
                    J’ai beaucoup aimé aussi l’apprentisage d'un Anglais technique, la mise en place d'un RGPD, la conception et la modélisation d'une base de donnée (Merise et UML), le développement en programmation orienté objet en php à l'aide du framework Symfony.
                    <br/><br/>
    
                </div>
            </>
            },
        {
            title: 
            <>
                <div className="title"> 
                    <span className="period"> 2006 - 2014:</span> ISTA SLCG - Releveur </div></>,
            content: 
            <>
                <div className="contentFormation">
    
                    <div className="subtitle"> Poste:</div>
                    Releveur - Poseur<br/>
    
                    <div className="subtitle"> Situation:</div>
                    Relever les index de compteurs d'eaux froide/chaude, gaz, et de répartittion de frais de chauffage. Pose de compteurs électroniques pour le relevé à distance dans tout le Bas Rhin.<br/>
                    
                    <div className="subtitle"> Enjeux:</div>
                    Satisfaire les clients par la qualité des services. Faire mieux que la concurrence.<br/>
                    
                    <div className="subtitle">Difficultées:</div>
                    - Cadence des relevés<br/>
                    - Accessibilité de compteurs (fosses, ambassades, representation, caves, scellé, etc ...) <br/>
                    - Géolocalisation des clients et compteurs<br/>
                    - Gestion des litiges<br/>
                    
    
                    <div className="subtitle">Missions:</div>
                    -   Planifications et ordonnancements des passages<br/>
                    -	Réalisation et affichage des avis de passages<br/>
                    -	Gestion du stock du matériel de relèves<br/>
                    -   Relever les index sur bordereaux papiers et sur terminal serveur<br/>
                    -   Décharger les affaires sur serveurs et remplir les rapports de semaine.
                    -	Repérer et Gérer les fuites d'eau, consommations anormales<br/>
                    -	Remplacements, poses, soudages, paramétrages de compteurs électroniques pour le relevé à distance (wifi)<br/>
                    -	Remplacement des tubes alcool<br/>
                    -   Sceller les compteurs, contrôler les fraudes.<br/>
                    -	Prendre des rendez vous, chercher les clés/badges/codes d'accès aux compteurs<br/>
                    -	Faire les deuxièmes et troisièmes passages<br/>
                    
                    <div className="subtitle"> Outils employés:</div>
                    Terminal serveur portable, poste à souder, clés spéciales, scéllés, avis de passages, bordereaux, gps, etc ...<br/>
    
                    <div className="subtitle">Résultats:</div>
                    Augmentation de 20% du chiffres d'affaires. 8000 à 13000 compteurs relevés par mois. 1500 à 3000 km parcouru dans le Bas Rhin par mois<br/>
    
                    <div className="subtitle">Evaluations:</div>
                    Il m'a fallu être performant, très organisé, motivé et endurant (5 a 7 km de marche par jour). J'ai acquis des compétences techniques (notamment de soudages par exemple ou sur la transmissions de données à distances). J'ai développé une très bonne connaissance des villes, et villages du département du Bas Rhin (Du val d'argent à Wissembourg). Mon sens de la discrétion et de la relation client s'est grandement amélioré, ainsi que ma maîtrise de la gestion du stress  <br/><br/>
    
                </div>
            </>
            },
            
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 2002 - 2005:</span> Université de Strasbourg  - Pôle Multimédia - Webdesigner/Intégrateur <span className="graduate">Editions Web App</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Webdesigner Développeur Front End - Infographiste - Intégrateur HTML <br/>
        
                        <div className="subtitle"> Situation:</div>
                        Conceptions et Développements de Sites, Applications Web (enseignements à distance), Web tv, Chaîne de télévision universitaire, Colloques et Conférences lives et différé, bornes intéractives, destinés aux étudiants, enseignants et chercheurs<br/>
                        
                        <div className="subtitle"> Enjeux:</div>
                        Concevoir et développer l'enseignement nomade, à distance principalement. Soutien à l’enseignement et à l’apprentissage utilisant les plates-formes virtuelles <br/>
                        
                        <div className="subtitle">Missions:</div>
                        -	Webdesigner les interfaces des applications web, sites web, Chaines tv, Webs tv, Cd rom promotionnel
                        -   Interfacage web live et différé des salles de cours avec suivi auto tracking vidéo
                        -	UX, UI designer des applications et site web (CMS cours en ligne, Forum d’échange, VOD, plateforme d’enseignement à distance.
                        -   Interfacage web live et différé des salles de cours avec suivi auto tracking vidéo
                        -	Conception et réalisations de charte graphiques. Logotypage.
                        -	Encodage des vidéos et déploiement de serveur vidéo (streaming, live, différé)
                        -	Déploiements des applications et sites web
                        -	Infographie affiche évènementiel, pochette Cd Rom, dépliants, …
                        -	Prise de vues (photographique et vidéo), numérisations

                        <div className="subtitle"> Outils employés:</div>
                        Adobe Illustrator, Photoshop, Indesign, HTML CCS Javascript, Asp, Sql, Flash.<br/>
        
                        <div className="subtitle">Résultats:</div>
                        Fondation et developpement de l'enseignement et de la formation à distance<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        J'ai appris de manière autodidacte le métier de webdesigner et d'intégrateur HTML. Ce fut un travail très enrichissant par les nombres de domaines couverts. J'ai eu la chance de participer aux fondements de l'enseignements à distance en Alsace, d'avoir rencontré d'éminents chercheurs, scientifiques, et professeurs. Je faisais parti d'une équipe de deux développeurs, d'un chef de projet, et d'une chargée de communication<br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period">2004: </span> Webmachine (Agence Novembre) - Stage en entreprise - Webdesigner <span className="graduate">Editions Web</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Stage - Intégrateur - Webdesigner<br/> 
        
                        <div className="subtitle"> Situation:</div>
                        Stage de formation à la technologie Flash à Webmachine Strasbourg<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -   Webdesigner les sites en cours <br/>
                        -	Développement flash (jeu et animations)<br/>
                        -	Intégration de données mobiles<br/>
                        


                        <div className="subtitle"> Outils employés:</div>
                        Html, CSS, Illustrator, Photoshop, Flash <br/>
        
                        <div className="subtitle">Résultats:</div>
                        J'ai pu travailler sur le webdesign et les animations flash d'une dizaines de sites<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        Je me suis perfectionné sur la technologie Flash. J'ai pu voir ce qu'était le métier de webdesigner en dehors de l'Université de Strasbourg<br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 2001:</span> Aventis (siège mondial) - Elyo Nord Est - Technicien polyvalent </div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Employé Service Courrier et Colis, co responsable - gestionnaire du Service télécommunication <br/>
        
                        <div className="subtitle"> Situation:</div>
                        Répondre à la demande du service Courrier et Colis et du service télécommunication pour le personnel du siège mondial d'Aventis <br/>
                        
                        <div className="subtitle">Missions:</div>
                        -	Distributions, expéditions et acheminements du courrier et colis des différents départements du siège. <br/>
                        -	Gestion du parc de téléphonie fixe et mobile, de l’auditorium, et des salle de téléconférences ( 200 téléphones fixes et mobiles, 9 salles de conférences, 1 auditorium, 1 salle des marchés). <br/>
                        -	Chargé de planification et mise en relation des téléconférences nationales et internationales (USA, Allemagne, France). <br/>
                        -   Former le personnel à l'utilisation de téléphone mobile <br/> 

                        <div className="subtitle"> Outils employés:</div>
                        Autocom, télépod, caméra, beamer, vidéo projecteur, ordinateur portable, téléphones fixes et mobiles, balances, affranchisseurs, scanneur de colis<br/>
        
                        <div className="subtitle">Résultats:</div>
                        J'ai fait évolué et développé le service de télécommunication. Couverture en téléphonie mobile de l'ensemble des employés<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        J'ai fait preuve de diplomatie et de discrétion avec le board du siège et les employés. J'ai répondu avec précision et qualité aux demandes formulées. J'ai fait face à un stress important en restant calme. J'ai appris à affranchir les colis et courriers, à me servir des machines d'affranchissements. <br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 2000:</span> Imprimerie Luck et Fils (Bopack) - Infographiste <span className="graduate">Editions Graphiques</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Chargé de planning, opérateur PAO, Infographiste<br/>
        
                        <div className="subtitle"> Situation:</div>
                        Impression de produits adhésifs et sérigraphiques (étiquettes d'emballages de marques)<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -	Infographie de produits autocollants <br/>
                        -	flashage des films d'impression<br/>
                        -   insolation des plaques photosensibilisées d'impression offset<br/>
                        -	Chargé de la planification avant impression des commandes<br/>
                        -   préparation des planches de découpes <br/>
                        

                        <div className="subtitle"> Outils employés:</div>
                        Adobe Illustrator, Photoshop, Indesign, Flasheuse<br/>
        
                        <div className="subtitle">Résultats:</div>
                        Impressions de produits d'entreprises comme Lilly, les Grands Chais de France, Weleda, Behr France, General Motors, l'Oréal, etc  <br/>
        
                        <div className="subtitle">Evaluations:</div>
                        Mise en pratique de savoir-faire et compétances acquises durant mon BTS industries graphiques<br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 2000:</span> Office Dépot Strasbourg - Lead image <span className="graduate">Editions Graphiques</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Responsable de la section impression, infographiste <br/> 
        
                        <div className="subtitle"> Situation:</div>
                        Responsable d'une équipe de trois personnes de la section impression, tampons et photocopies et ventes informatique<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -	Conseil, assistances en photocopies et impressions<br/>
                        -   Prendre commande des produits à imprimer
                        -	Faire des photocopies<br/>
                        -   Concevoir des tampons personnalisés<br/>
                        -	Conseils et ventes de produits informatiques<br/>
                        -   Gestion de 8 photocopieurs, de leurs consommables et de leur maintenances <br/>
                        -   Gestion de la caisse et de l'équipe<br/>
                        

                        <div className="subtitle"> Outils employés:</div>
                        Photocopieurs<br/>
        
                        <div className="subtitle">Résultats:</div>
                        Développement du chiffre d'affaire de la section impression offset<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        C'est la première fois que je gérais une équipe. J'ai beaucoup aimé.<br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 1998 - 1999:</span> Service National - Gendarme Auxiliaire ( BT Woerth) </div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Gendarme Auxiliaire <br/> 
        
                        <div className="subtitle"> Situation:</div>
                        Missions de gendarmerie, classe au Ciga Auxerre. Gendarme auxiliaire dans la compagnie de Wissembourg, détaché à la brigade territoriale de Woerth<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -	Patrouilles sur la compagnie, sécurité générale, surveillance et protection de lieux stratégiques<br/>
                        -	Sécurité et prévention routières, assistances aux publics en danger<br/>
                        -	Interventions, maintien de l’ordre, renseignement, plan de recherche<br/>
                        -	Investigation, police technique et scientifique (section de recherche à Haguenau), coopération financière aux frontières avec l’Allemagne<br/>
                        -	Transferts de détenus, sécurisation lieu stratégique.<br/>


                        <div className="subtitle"> Outils employés:</div>
                        Terminal de gendarmerie, armes , équipements de dotation, véhicules de gendarmeries<br/>
        
                        <div className="subtitle">Résultats:</div>
                        Pour servir partout.<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        Mon passage dans la gendarmerie m'a appris à intervenir sur des situations difficiles, a garder mon sang froid et à gérer le stress (responsabilité de l'usage des armes), a porter secours et prodiguer les premiers soins de base. J'ai eu la chance de participer à toutes les missions de gendarmerie <br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 1998:</span> Imprimerie Regionale (IREG) - Stage en entreprise - Opérateur PAO <span className="graduate">Editions Graphiques</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Deviseur, Opérateur PAO, Correcteur, Imprimeur, Faconneur <br/> 
        
                        <div className="subtitle"> Situation:</div>
                        Immersion en entreprise à l'imprimerie régionnale, suivi de toute la chaine graphique<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -   Faire les devis sur différentes commandes
                        -	Publication assistée par ordinateur pour des petites commandes<br/>
                        -	Flashage des films<br/>
                        -	Insolation des plaques photosensibilisées d'impression offset<br/>
                        -	Lectures et corrections des épreuves<br/>
                        -   Commander les matrices de découpes et d'embossage/gaufrage <br/>
                        -   Découpes, gaufrage et embossage, dorure, numérotage <br/>
                        -   Impression offset<br/>
                        -	Faconnage<br/>


                        <div className="subtitle"> Outils employés:</div>
                        Quark Xpress, Photoshop, Illustrator, Pantonnier, Flasheuse, Imprimante Offset/Rotative, Relieuse, Piqueuse, Massicot  <br/>
        
                        <div className="subtitle">Résultats:</div>
                        Participer à toutes les sections et ateliers d'une imprimerie<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        Ce stage en entreprise m'a permis de suivre et de travailler sur les différentes étapes de la chaine graphique d'une imprimerie.  <br/><br/>
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                        <span className="period"> 1997:</span> Etoile Alsace Impression (Succursale à Molsheim) - Stage en entreprise - Responsable Pré Presse <span className="graduate">Editions Graphiques</span></div></>,
                content: 
                <>
                    <div className="contentFormation">
        
                        <div className="subtitle"> Poste:</div>
                        Responsable du service Pré Presse, opérateur PAO<br/> 
        
                        <div className="subtitle"> Situation:</div>
                        Chargé de clientelle. Responsable Pré Presse dans la succursale de l'imprimerie EAI à Molsheim<br/>
                        
                        <div className="subtitle">Missions:</div>
                        -   Conseil en publication
                        -   Faire les devis, prendre les commandes
                        -	Promouvoir et vendre les gammes de Faire-Parts, Invitations, Remerciements, En-têtes de lettre, Enveloppes, Cartes de visite <br/>
                        -   Conception et publication de la plaquette de la mairie de Molsheim<br/>


                        <div className="subtitle"> Outils employés:</div>
                        Quark Xpress, Photoshop, Illustrator, Pantonnier <br/>
        
                        <div className="subtitle">Résultats:</div>
                        J'ai développer les ventes de produits en publications<br/>
        
                        <div className="subtitle">Evaluations:</div>
                        Ce stage en entreprise m'a permis de découvrir et d'exercer les responsabilités d'un chef de service pré presse, de promouvoir et de vendre des publications imprimées. <br/><br/>
        
                    </div>
                </>
            },
            
            
            
    
        
        
        // {
        // title: 'test',
        // content: <><div className="contentFormation"> <Link to={'/accueil'} className="linkFormation">toto</Link> toto <br/> - tata</div> <div>top</div></>
        // },
        
];