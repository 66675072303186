import React from "react";
import './socialNetwork.css'
import { FaFacebookSquare} from 'react-icons/fa';
import { FaTwitterSquare} from 'react-icons/fa';
import { FaLinkedin} from 'react-icons/fa';
import { FaGithubSquare} from 'react-icons/fa';

const SocialNetworks = () => {
    const anim = () => {
        const icons = document.querySelectorAll(".social-network a");

        icons.forEach((link) => {
        link.addEventListener("mouseover", (e) => {
            link.style.transform = `translate(${e.offsetX - 10}px, ${
            e.offsetY - 13
            }px)`;
        });

        link.addEventListener("mouseleave", () => {
            link.style.transform = "";
        });
        });
    };

    return (
        <div className="social-network">
            <ul>
                <a
                href="https://www.linkedin.com/in/cyrillechristmann/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
                onMouseOver={anim}
                >
                <li>
                    {<FaLinkedin size='40px' />}
                </li>
                </a>
                <a
                href="https://github.com/cychristmann"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
                onMouseOver={anim}
                >
                <li>
                    {<FaGithubSquare size='40px' />}
                </li>
                </a>
                <a
                href="https://www.facebook.com/cyrillechristmann14476"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
                onMouseOver={anim}
                >
                <li>
                    {<FaFacebookSquare size='40px' />}
                </li>
                </a>
                <a
                href="https://twitter.com/cych14"
                target="_blank"
                rel="noopener noreferrer"
                className="hover"
                onMouseOver={anim}
                >
                <li>
                    {<FaTwitterSquare size='40px' />}
                </li>
                </a>
                
            </ul>
        </div>
    );
};

export default SocialNetworks;
