import React from 'react'
import logo from '../../images/logocc3.png'
import './logo.css'

const Logo = () => {
    return (
        
            <img src={logo} className='logo' alt="logo" />
        
    )
}

export default Logo
