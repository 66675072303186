import React from 'react'


const ErrorPage = () => {
    return (
        <div>
            
                <h1> Oups, cette page n'existe pas</h1>
                        
        </div>
    )
}

export default ErrorPage