import React, { useRef } from "react";
import './contactForm.css'
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
init(process.env.ID);

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        const formMess = document.querySelector(".formMessage");

        emailjs
        .sendForm(
            "service_nlyuhrh",
            "template_421vj9g",
            form.current,
            process.env.REACT_APP_ID
        )
        .then(
            (res) => {
            console.log(res.text);
            form.current.reset();
            formMess.innerHTML = "<p className='success'>Message envoyé !</p>";

            setTimeout(() => {
                formMess.innerHTML = "";
            }, 2000);
            },
            (err) => {
            console.log(err.text);
            formMess.innerHTML =
                "<p className='error'>Une erreur s'est produite, veuillez réessayer</p>";

            setTimeout(() => {
                formMess.innerHTML = "";
            }, 2000);
            }
        );
    };

    return (
        <div className="form-container">

            <div className="form-title">Bonjour, vous pouvez me contacter par ce formulaire.</div>
            <form ref={form} onSubmit={sendEmail} className="form-content">
                <label>Votre Nom</label>
                <input type="text" name="name" required autoComplete="off" id="name" />
                <label>Votre Email</label>
                <input
                type="email"
                name="email"
                required
                autoComplete="off"
                id="email"
                />
                <label>Votre Message</label>
                <textarea name="message" id="mess" />
                <input type="submit" value="Envoyer" className="hover button" />
            </form>
            <div className="formMessage">
                {}
            </div>
        </div>
    );
};

export default ContactForm;