import React from 'react';
import './imgPrez.css';

function ImgPrez(props) {
    const { src, alt } = props;
    return (
        <img src={src} alt={alt} className="imgPrez"/>
    );
}

export default ImgPrez;