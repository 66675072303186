import React, {useState} from 'react';
import './accordion.css';
import { AiFillPlusCircle} from 'react-icons/ai';
import { AiFillMinusCircle} from 'react-icons/ai';

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);
    
    return (
        <div className="accordion">
            <div className="accordion-itema">
                <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                    <div>{title}</div>
                    <div className="more">{isActive ? <AiFillMinusCircle size='23px' /> : <AiFillPlusCircle size='23px' /> }</div>
                </div>
                {isActive && <div className="accordion-content">{content}</div>}
            </div>
        </div>
    );
};

export default Accordion;

