import React, { useState } from 'react'
import { projectsData} from '../PortfolioPage/projectsData'
import './project.css'

const Project = ({ projectNumber}) => {
    const [currentProject, SetCurrentProject] = useState(projectsData[projectNumber])

    //console.log(currentProject)
    
    return (
        <div className='project-main'>
            <div className="project-content">
                

                <div className="img-container">                    
                    <img
                        src={currentProject.img}
                        alt={currentProject.title}
                        className="img"
                    />
                </div>

                <div className='project-title'>{currentProject.title}</div>

                <p className='project-date'>{currentProject.date}</p>

                <ul className="project-languages">
                    {currentProject.languages.map((itemLanguages) => (
                        <li key={itemLanguages}>{itemLanguages}</li>
                    ))}
                </ul>

                
                {/* <span>
                        
                        <p>{currentProject.infos}</p>
                </span> */}
                
                <div className="button-container">
                    <a
                        href={currentProject.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover"
                    >
                        <span className="button">Voir le site</span>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default Project