import React , {useRef, useEffect, useState, Fragment} from 'react'
import { Link } from 'react-router-dom';
import DynamicText from '../DynamicText/DynamicText';
import PhotoCC from '../PhotoCC';
import SocialNetworks from '../SocialNetworks';
import { BsMailbox2} from 'react-icons/bs';
import { AiFillCar} from 'react-icons/ai';
import { MdOutlineHomeWork} from 'react-icons/md';
import Logo from '../Logo/Logo';
import './introductionPage.css';
import ImgPrez from '../ImgPrez/ImgPrez';

const IntroductionPage = () => {

    

    return (
        <main className='presentation-container'>

            <h1>Qui suis je ?</h1>


            <div className='profil-container'>
                
                <div className='profil-card'>
                    <div className='imgBx'>
                        {<PhotoCC className="img" />}
                    </div>
                    <div className='profil-content'>
                        <div className='profil-details'>
                            <h4>Cyrille CHRISTMANN  47 ans <br/> 
                                <span>Développeur Web/Application Web Fullstack</span><br/>
                                <span>
                                    {<MdOutlineHomeWork size='20px' />} Habite à Strasbourg <br />
                                    {<AiFillCar size='20px' />}Possède permis voiture et voiture <br />
                                    {<BsMailbox2 size='20px' />} christmanncyrille@yahoo.fr <br />
                                </span>
                            </h4>
                            
                        </div>
                        
                    </div>
                
                </div>
    
                
                
                
                {<SocialNetworks/>}
            </div>
            
            

            {/* < DynamicText /> */}
        

            <div className='contentPrez'>


                <div className="textPrez">
                    <p>Bonjour et bienvenue à vous ! Comme dirait un célèbre arbre de chez Marvel: je s'appelle Cyrille Christmann. Infographiste, Opérateur PAO, Concepteur multimédia de formation (BTS Industries Graphiques option communication graphique: pré-presse) et attiré depuis toujours par les nouvelles technologies et le monde digital (je possède aussi un baccalauréat STI Electronique), c'est tout naturellement que je me suis orienté lors de mes débuts professionnels vers le webdesign de site Internet.</p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/babyGroot.jpg'} alt={'Bébé groot salut'} />
                </div>


                <div className="textPrez">
                    <p>Par le passé j'ai pu exercer mes compétences pendant trois ans en tant que Infographiste - Webmaster - Intégrateur - Webdesigner au pôle multimédia de l'Université de Strasbourg. Ma principale activité était de concevoir et réaliser les interfaces utilisateurs des sites et applications web destinés aux enseignants, chercheurs et élèves de l'université.</p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/desktop.jpg'} alt={'Bureau'} />
                </div>


                <div className="textPrez">
                    <p>Mon métier est de concevoir et réaliser des produits communicants et services numériques attractifs, conviviaux et facile à utiliser en mélant textes, images, vidéos, sons et intéractions. Ces dernières années il a beaucoup évolué, les supports de destination d'informations ont évolué. Ils ont de tout temps évolué, allant de la pierre à la dématérialisation numérique. J'ai commencé par travaillé sur divers supports (papier,cd,dvd,) puis mon travail c'est déporté sur Internet, sur le web pour divers dispositifs (bornes intéractives, écrans d'ordinateurs, écrans tv).</p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/webdesign.jpg'} alt={'Webdesign'} />
                </div>


                <div className="textPrez">
                    <p>Aujourd'hui toujours autant passionné et à l'heure où l'Internet se spécifie de jour en jour, où le périmètre technique est vaste et s'agrandit sans cesse j'ai élargi ma palette de compétences et recherche un emploi de web développeur fullstack.</p>
                    <p>Touche-à-tout et curieux de nature, je fais beaucoup de veille technologique, et m'autoforme sur les principaux langages web, les nouvelles technologies. Mes expériences professionelles et mon large panel de compétences me permettent de mener à bien des missions, ou projets très variés. Technicien polyvalent, je suis capable de m'adapter rapidement aux diverses situations, aux besoins du moment.</p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/tv3d1.jpg'} alt={'Tv futuriste'} />
                </div>


                <div className="textPrez">
                <p>Fasciné par les sciences, les découvertes, je m'intéresse de plus en plus aux objets connectés, au futur de l'Internet. Je rêve du jour où les voitures communiqueront entre elles par exemple, du jour où je regarderais une tv holographique à partir d'un cube de 1 cm3.</p>
                <p>Je prends plaisir aussi à créé des œuvres originales, de l'art digital. Je suis constamment à la recherche de nouvelles idées et de moyens innovants (l'Intelligence Artificielle par exemple) pour exprimer ma créativité. Les illustrations de ce site sont toutes de ma conception. Sur cette galerie, vous pourrez découvrir un large éventail de mes créations:  <a href="http://adscribere.info" target="_blank" rel="noopener noreferrer">http://adscribere.info</a></p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/comCar.jpg'} alt={'Voiture Communicante'} />
                    <ImgPrez src={'./assets/img/imgPrez/tv3d2.jpg'} alt={'Tv futuriste'} />
                </div>


                <div className="textPrez">
                
                    <p>Pour finir je citerais le physicien Edwin Herbert Land: Innover, ce n'est pas avoir une nouvelle idée mais arrêter d'avoir une vieille idée. </p>
                </div>
                <div className="imagePrez">
                    <ImgPrez src={'./assets/img/imgPrez/rodinBaby.jpg'} alt={'Le bébé penseur de rodin futuriste'} />
                </div>


            
            </div>
        </main>
    )
}

export default IntroductionPage