export const projectsData = [
  {
    id: 56,
    title: "Api Rest Pokemon",
    date: "2024",
    languages: ["Node, Express, Mysql, Sequelize, JWT, Cors"],
    infos: "Une API REST sur les pokemons",
    img: "./assets/img/portfolio/apirestpoke.png",
    link: "http://apipokemon.pson.be/api/pokemons",
  },
  {
    id: 55,
    title: "Hot Dang Homes",
    date: "2024",
    languages: ["React, Nextjs, Wordpress Headless, Graphql"],
    infos: "front end nextjs back end wordpress headless graphql",
    img: "./assets/img/portfolio/hotdanghome.png",
    link: "https://hotdanghomenextjs.vercel.app/",
  },
  {
    id: 54,
    title: "Imbd CyCh",
    date: "2023",
    languages: ["React, Nextjs, TMBD api, Tailwind css"],
    infos: "front end api TMDB avec Nextjs",
    img: "./assets/img/portfolio/imdb.png",
    link: "https://imdb-cych.vercel.app/",
  },
  {
    id: 53,
    title: "CC Vidéos Médias",
    date: "2023",
    languages: ["React, RapidApi, MUI, Axios"],
    infos: "front end api youtube v3 2023",
    img: "./assets/img/portfolio/ccmedia.png",
    link: "http://ccmedia.pson.be",
  },
  {
    id: 52,
    title: "Adscribere - Galerie Art Digital",
    date: "2023",
    languages: ["Wordpress, Art Digital, IA"],
    infos: "Art Digital - Cyrille Christmann 2023",
    img: "./assets/img/portfolio/adscribere.png",
    link: "http://adscribere.info",
  },
  {
    id: 45,
    title: "CV 2024",
    date: "2023",
    languages: ["React"],
    infos: "Site CV Cyrille Christmann 2024",
    img: "./assets/img/portfolio/cv2022.png",
    link: "http://cyrillechristmann.info",
  },
  {
    id: 46,
    title: "Phoenix Taekwondo TV-VOD",
    date: "2021",
    languages: ["Symfony", "PHP", "Viméo", "Stripe"],
    infos: "Plateforme VOD Phoenix Taekwondo",
    img: "./assets/img/portfolio/tkdvod.png",
    link: "https://phoenix.pson.be/",
  },
  {
    id: 51,
    title: "Générateur de Galaxie",
    date: "2022",
    languages: ["Three js", "Webgl", "Vercel"],
    infos: "Essai de Three Js",
    img: "./assets/img/portfolio/galaxie.png",
    link: "https://gengalaxie.vercel.app/",
  },
  {
    id: 49,
    title: "3D texte",
    date: "2022",
    languages: ["Three js", "Webgl", "Vercel"],
    infos: "Essai de Three Js",
    img: "./assets/img/portfolio/3dcych.png",
    link: "https://3dcych.vercel.app/",
  },
  {
    id: 50,
    title: "3D Maison Hantée",
    date: "2022",
    languages: ["Three js", "Webgl", "Vercel"],
    infos: "Essai de Three Js",
    img: "./assets/img/portfolio/maisonhantee.png",
    link: "https://hauntedhouse-theta.vercel.app/",
  },
  {
    id: 47,
    title: "Marvel Quiz",
    date: "2022",
    languages: ["React", "Firebase"],
    infos: "Quiz sur les héros Marvel",
    img: "./assets/img/portfolio/marvelquiz.png",
    link: "http://marvelquiz.pson.be/",
  },
  {
    id: 48,
    title: "Pokedex",
    date: "2022",
    languages: ["Angular", "Firebase"],
    infos: "Annuaire Pokémon",
    img: "./assets/img/portfolio/pokemon.png",
    link: "https://ng-pokemon-app-7aad4.web.app/",
  },
  {
    id: 43,
    title: "Cook App",
    date: "2022",
    languages: ["React", "Api", "Axios"],
    infos: "Site Recette Cuisine",
    img: "./assets/img/portfolio/cookapp.png",
    link: "https://psoncooking.netlify.app/",
  },
  {
    id: 44,
    title: "World Flag",
    date: "2022",
    languages: ["React", "Api", "Axios"],
    infos: "Site Drapeau du monde",
    img: "./assets/img/portfolio/flagworld.png",
    link: "https://psondrapeau.netlify.app/",
  },
  {
    id: 1,
    title: "Acolad ",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Bureau virtuel",
    img: "./assets/img/portfolio/acolad.jpg",
    link: "http://acolad.u-strasbg.fr/",
  },
  {
    id: 2,
    title: "Ancel",
    date: "2005",
    languages: ["Webdesign", "PHP"],
    infos: "Site DR Oetker France",
    img: "./assets/img/portfolio/ancel.jpg",
    link: "http://www.ancel.fr",
  },
  {
    id: 3,
    title: "Canal Campus",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Web Tv Universitaire",
    img: "./assets/img/portfolio/canalcampus.jpg",
    link: "http://www.canalc2.tv/",
  },
  {
    id: 4,
    title: "Cardiac Imaging",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Universitaire",
    img: "./assets/img/portfolio/cardiacimaging.jpg",
    link: "http://cardiacimaging.u-strasbg.fr/",
  },
  {
    id: 5,
    title: "Cent Ans",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Universitaire",
    img: "./assets/img/portfolio/centans.jpg",
    link: "http://w3appli.u-strasbg.fr/100ans/",
  },
  {
    id: 6,
    title: "Colloques et Conférences",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Colloques et Conférences Universitaires",
    img: "./assets/img/portfolio/cetc.jpg",
    link: "http://www.canalc2.tv/",
  },
  {
    id: 7,
    title: "Cours En Ligne",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "CMS Cours en ligne",
    img: "./assets/img/portfolio/coursenligne.jpg",
    link: "http://coursenligne.u-strasbg.fr/",
  },
  {
    id: 8,
    title: "Tourisme Alsace",
    date: "2005",
    languages: ["Webdesign", "PHP"],
    infos: "Site Tourisme Alsace",
    img: "./assets/img/portfolio/crt.jpg",
    link: "https://www.visit.alsace/",
  },
  {
    id: 9,
    title: "Cuisinella",
    date: "2005",
    languages: ["Webdesign", "PHP"],
    infos: "Site Tourisme Alsace",
    img: "./assets/img/portfolio/cuisinella.jpg",
    link: "https://www.ma.cuisinella/fr-fr",
  },
  {
    id: 10,
    title: "US DEAMC",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site DEA physique de la matière condensée",
    img: "./assets/img/portfolio/deamc.jpg",
    link: "http://www.physique-ingenierie.unistra.fr",
  },
  {
    id: 11,
    title: "DESS SNVIE",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site DESS SNVIE",
    img: "./assets/img/portfolio/desssnvie.jpg",
    link: "http://coursenligne.u-strasbg.fr/dess-snvie/",
  },
  {
    id: 12,
    title: "EAD 2000",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Plateforme d'enseignement à distance",
    img: "./assets/img/portfolio/ead2000.jpg",
    link: "https://www.unistra.fr/etudes/lenseignement-a-distance-ead",
  },
  {
    id: 13,
    title: "EMK",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site EMK cuisine en kit",
    img: "./assets/img/portfolio/emk.jpg",
    link: "http://www.emk.fr/",
  },
  {
    id: 14,
    title: "EPPUN",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site EPPUN",
    img: "./assets/img/portfolio/eppun.jpg",
    link: "http://eppun.u-strasbg.fr",
  },
  {
    id: 15,
    title: "Ermitage",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site Fromage Ermitage",
    img: "./assets/img/portfolio/ermitage.jpg",
    link: "https://www.ermitage.com/",
  },
  {
    id: 16,
    title: "E Schola",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site E Schola",
    img: "./assets/img/portfolio/eschola.jpg",
    link: "http://eschola.u-strasbg.fr",
  },
  {
    id: 17,
    title: "ESV",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Cartable Numérique",
    img: "./assets/img/portfolio/esv.jpg",
    link: "http://eschola.u-strasbg.fr",
  },
  {
    id: 18,
    title: "Faculté de Médecine",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Faculté de Médecine",
    img: "./assets/img/portfolio/facmedecine.jpg",
    link: "https://med.unistra.fr/",
  },
  {
    id: 19,
    title: "Foire Européenne",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site Foire Européenne",
    img: "./assets/img/portfolio/foireeurop.jpg",
    link: "https://www.foireurop.com/",
  },
  {
    id: 20,
    title: "Forum Langues",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Forum Langues",
    img: "./assets/img/portfolio/forumlangues.jpg",
    link: "https://forumlangues.u-strasbg.fr",
  },
  {
    id: 21,
    title: "Golf de la Wantzenau",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site du Golf de la wantzenau",
    img: "./assets/img/portfolio/golfwantz.jpg",
    link: "https://golf-wantzenau.fr/fr/",
  },
  {
    id: 22,
    title: "Info Ado",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Info Ado",
    img: "./assets/img/portfolio/infoado.jpg",
    link: "https://www.maisondesados-strasbourg.eu/cafe-info-pro-echanges-autour-de-la-transidentite-2/",
  },
  {
    id: 23,
    title: "Société Japonaise",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site de la société Japonaise",
    img: "./assets/img/portfolio/jsps.jpg",
    link: "https://jsps.unistra.fr/",
  },
  {
    id: 24,
    title: "Les Essentiels",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Plateforme Vod",
    img: "./assets/img/portfolio/lesessentiels.jpg",
    link: "http://lesessentiels.u-strasbg.fr/",
  },
  {
    id: 25,
    title: "Mamit TRNA",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Mamit TRNA",
    img: "./assets/img/portfolio/mamittrna.jpg",
    link: "http://mamit-trna.u-strasbg.fr/",
  },
  {
    id: 26,
    title: "Managing",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site Managing",
    img: "./assets/img/portfolio/managing.jpg",
    link: "http://managing.fr/fr/",
  },
  {
    id: 27,
    title: "Millepatte",
    date: "2005",
    languages: ["Webdesign", "Logo"],
    infos: "Site Millepatte agence de services à la personne",
    img: "./assets/img/portfolio/Millepatte.jpg",
    link: "https://millepatte.com/",
  },
  {
    id: 28,
    title: "Maison France Japon",
    date: "2002-2005",
    languages: ["Webdesign", "1 er site professionnel"],
    infos: "Site Maison France Japon",
    img: "./assets/img/portfolio/mufj.jpg",
    link: "https://mufrancejapon.u-strasbg.fr/",
  },
  {
    id: 29,
    title: "Primato",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Centre de primatologie",
    img: "./assets/img/portfolio/primato.jpg",
    link: "https://primatologie.unistra.fr/",
  },
  {
    id: 30,
    title: "Prim'Cart",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Cartable virtuel",
    img: "./assets/img/portfolio/primcart.jpg",
    link: "http://www.primcart.com",
  },
  {
    id: 31,
    title: "Qualité Pharma",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Qualité Pharma",
    img: "./assets/img/portfolio/qualitepharma.jpg",
    link: "https://www.unistra.fr/etudes/decouvrir-nos-formations/par-facultes-ecoles-instituts/",
  },
  {
    id: 32,
    title: "Science de l'éducation",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site des sciences de l'éducation",
    img: "./assets/img/portfolio/sceduc.jpg",
    link: "https://inspe.unistra.fr/",
  },
  {
    id: 33,
    title: "Scriptic",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Scriptic",
    img: "./assets/img/portfolio/scriptic.jpg",
    link: "w3appli.u-strasbg.fr/scriptic/",
  },
  {
    id: 34,
    title: "Spermiologie",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site Spermiologie",
    img: "./assets/img/portfolio/spermiologie.jpg",
    link: "https://sfc.unistra.fr/formations/sante-laboratoires-danalyses_-_laboratoires-danalyses-medicales_-_spermiologie-et-infertilite-masculine_-_2362/",
  },
  {
    id: 35,
    title: "ULP Stage Emploi",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site  ULP Stage  Emploi",
    img: "./assets/img/portfolio/stageemploi.jpg",
    link: "https://www.unistra.fr/recrutement/personnels-administratifs-et-techniques/offres-demplois",
  },
  {
    id: 36,
    title: "The Blue Cat",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site The Blue Cat",
    img: "./assets/img/portfolio/thebluecat.jpg",
    link: "https://www.the-blue-cat.com/",
  },
  {
    id: 37,
    title: "Tourissimo",
    date: "2005",
    languages: ["Webdesign", "php"],
    infos: "Site Tourissimo",
    img: "./assets/img/portfolio/tourissimo.jpg",
    link: "https://www.tourissimo-strasbourg.com/",
  },
  {
    id: 38,
    title: "ULP Multimédia",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site  ULP Multimédia",
    img: "./assets/img/portfolio/ulpmm.jpg",
    link: "https://www.unistra.fr/recrutement/personnels-administratifs-et-techniques/offres-demplois",
  },
  {
    id: 39,
    title: "Univ-R",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Enseignement à Distance",
    img: "./assets/img/portfolio/univr.jpg",
    link: "http://univ-r.u-strasbg.fr/",
  },
  {
    id: 40,
    title: "U-TV",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Web télévision de l'Unistra",
    img: "./assets/img/portfolio/utv.jpg",
    link: "http://utv.u-strasbg.fr/",
  },
  {
    id: 41,
    title: "Vidéo Cours",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "vidéo cours en ligne",
    img: "./assets/img/portfolio/videocours.jpg",
    link: "http://videocours.u-strasbg.fr/",
  },
  {
    id: 42,
    title: "La Villa Média",
    date: "2002 - 2005",
    languages: ["Webdesign", "Asp", "Html", "Css", "Javascript"],
    infos: "Site La Villa Média",
    img: "./assets/img/portfolio/villamedia.jpg",
    link: "http://www.cafepedagogique.net/lemensuel/lesysteme/Pages/2002/actu_16_OUVERTUREDELAVILLAMEDIA.aspx",
  },
];
