import React, { useState } from 'react'
import Project from '../Project/Project'
import { projectsData } from './projectsData'
import './portfoliopage.css'

const PortfolioPage = () => {

    const [Data, SetData] = useState(projectsData)

    //console.log(Data[0].id)

    return (
        <main className='presentation-container'>

            <h1>Portfolio</h1>
            
            

            <div className='presentation-content-portfolio'>
                {Data.map((index, id) => (
                    <Project key={index} projectNumber={id}/>
                ))} 
                
            </div>

            
        </main>
    )
}

export default PortfolioPage

