import React from 'react';
import './prez3D.css';
import { Link } from 'react-router-dom';
import { FaUserGraduate} from 'react-icons/fa';
import { MdWork} from 'react-icons/md';
import { GiNetworkBars} from 'react-icons/gi';
import { BiMailSend} from 'react-icons/bi';
import { BsFillFileEarmarkCodeFill} from 'react-icons/bs';
import { AiFillHome} from 'react-icons/ai';

const Prez3D = () => {





    return (
        <div className='container3d'>
            <ul className='ul3d'>
                <li style={{zIndex:5}}> <Link to="/accueil">Qui suis je ? <AiFillHome  /> </Link> </li>
                <li style={{zIndex:4}}> <Link to="/formations">Formations <FaUserGraduate  /></Link></li>
                <li style={{zIndex:3}}> <Link to="/experiences">Expériences <MdWork /></Link> </li>
                <li style={{zIndex:2}}> <Link to="/competences">Compétences <GiNetworkBars /></Link> </li>
                <li style={{zIndex:1}}> <Link to="/portfolio">Portfolio <BsFillFileEarmarkCodeFill  /></Link> </li>
                <li style={{zIndex:0}}> <Link to="/contacts">Contact <BiMailSend /></Link> </li>
            </ul>
        </div>


    )
}

export default Prez3D