import React from 'react'
import Accordion from'../Accordion/Accordion'
import { accordionData } from './ContentWorkExperiences';

const WorkExperiencesPage = () => {
    return (
        <main className='presentation-container'>

            <h1>Expériences professionnelles</h1>
            
            

            <div className='presentation-content'>
                {accordionData.map(({ title, content }) => (
                    <Accordion key={title} title={title} content={content} />
                ))}
                
            </div>
        </main>
    )
}

export default WorkExperiencesPage