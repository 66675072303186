import { Link } from "react-router-dom";
import './contentWorkSkills.css';
import SkillBar from 'react-skillbars';



export const accordionData = [
        {
        title: 
        <>
            <div className="title"> 
                <span className="period"> {'>'}</span> Web - Back End 
            </div>
        </>,
        content: 
        <>
            <div className="contentFormation">
            
                <div className="subtitle">Algorithmie, logique </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 60
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">UML </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 60
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>
                
                <div className="subtitle">Méthode Agile SCRUM sur Azure DEVOPS </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Opérationnel",
                            "level": 60
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}
                
                <hr/>
                
                <div className="subtitle">Programmation Fonctionnelle et Orienté objet  </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Opérationnel",
                            "level": 60
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Programmation Réactive et WebClient  </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Apprenant",
                            "level": 45
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Java, Spring, Spring Boot, Spring Data, Maven, Hibernate, HQL, JPQL, Java EE, Servlets, JDBC </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Apprenant",
                            "level": 50
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Architectures Cloud Native et microservices  </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Apprenant",
                            "level": 45
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>
                
                <div className="subtitle">Php </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Opérationnel",
                                "level": 60
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}
                
                <hr/>            

                <div className="subtitle">Conception de bases de Données, Merise  </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Opérationnel",
                            "level": 60
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Mysql, Sql </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Opérationnel",
                            "level": 65
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Mongo DB </div>       
                    {<SkillBar 
                        skills={[
                        {
                            "type": "Apprenant",
                            "level": 40
                        }
                        ]} 
                        height={20} 
                        animationDelay={1000} 
                        animationDuration={1000}
                        colors={{
                            "bar": "#ffb800",
                            "title": {
                            "text": "#fff",
                            "background": "black"
                            }
                        }} 

                    />}

                <hr/>

                <div className="subtitle">Framework  Symfony (Doctrine, ORM, TWIG, Composer) </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Opérationnel",
                                "level": 65
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}

                <hr/>

                <div className="subtitle">Conception Api Rest, Sécurisation par Token JWT, Cors </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Opérationnel",
                                "level": 60
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}

                <hr/>

                <div className="subtitle">Node JS, Express, Sequelize, API REST </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Opérationnel",
                                "level": 55
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}

                <hr/>

                <div className="subtitle">Next JS , CSR, SSR, SSG, ISR, Optimisation des images, lazy loading</div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Opérationnel",
                                "level": 60
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}

                <hr/>

                <div className="subtitle">Conception Api GraphQL </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Apprenant",
                                "level": 45
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                        />}

                <hr/>

                <div className="subtitle">Python </div>       
                        {<SkillBar 
                            skills={[
                            {
                                "type": "Débutant",
                                "level": 40
                            }
                            ]} 
                            height={20} 
                            animationDelay={1000} 
                            animationDuration={1000}
                            colors={{
                                "bar": "#ffb800",
                                "title": {
                                "text": "#fff",
                                "background": "black"
                                }
                            }} 

                />}
                
                
                
                    
                    
        
                

            </div>
        </>
        },
        {
            title: 
            <>
                <div className="title"> 
                <span className="period"> {'>'}</span>  Web - Front End</div></>,
            content: 
            <>
                <div className="contentFormation">
                <div className="subtitle">Intégration Webdesign Webmaster </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 80
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>
                <div className="subtitle"> Design UX, UI, prototypage, Personas, Mockup, Axure, ADOBE XD </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 80
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle"> HTML, CSS </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 80
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle"> SASS </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Apprenant",
                                    "level": 45
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Responsif Design </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 70
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Javascript, Jquery </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 65
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Animation CSS et Javascript, Gsap </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 60
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Three JS, web 3d </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Apprenant",
                                    "level": 45
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Framework Bootstrap 4 et 5  </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 65
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Framework Tailwind  </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 65
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">React, Class, Hook, State, Props </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 65
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Angular </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Apprenant",
                                    "level": 45
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Typescript </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Débutant",
                                    "level": 40
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">Flash, Actionscript </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Opérationnel",
                                    "level": 55
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>

                <div className="subtitle">SEO, pertinence, notoriété </div>       
                            {<SkillBar 
                                skills={[
                                {
                                    "type": "Débutant",
                                    "level": 55
                                }
                                ]} 
                                height={20} 
                                animationDelay={1000} 
                                animationDuration={1000}
                                colors={{
                                    "bar": "#ffb800",
                                    "title": {
                                    "text": "#fff",
                                    "background": "black"
                                    }
                                }} 

                            />}
                
                <hr/>




                    
    
                </div>
            </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span> Versionning Git </div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Git, Github, GitLab</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Opérationnel",
                                                "level": 65
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                    
                    
                        
        
                    </div>
                </>
            },
        {
            title: 
            <>
                <div className="title"> 
                <span className="period"> {'>'}</span>  Administrations Systèmes DEVOPS</div></>,
            content: 
            <>
                <div className="contentFormation">
                    <div className="subtitle">Administration Linux, Debian </div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Opérationnel",
                                        "level": 55
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>

                    <div className="subtitle">Administration Serveur http (Apache), ftp</div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Opérationnel",
                                        "level": 55
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>

                    <div className="subtitle">Administration Serveur Java Tomcat</div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Apprenant",
                                        "level": 40
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>

                    <div className="subtitle">Docker</div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Testé 2-3x",
                                        "level": 40
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>

                    <div className="subtitle">Sécurisation des serveurs dédiés</div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Opérationnel",
                                        "level": 60
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>

                    <div className="subtitle">Administration serveur Cpanel, mutualisé, hébergement </div>       
                                {<SkillBar 
                                    skills={[
                                    {
                                        "type": "Opérationnel",
                                        "level": 60
                                    }
                                    ]} 
                                    height={20} 
                                    animationDelay={1000} 
                                    animationDuration={1000}
                                    colors={{
                                        "bar": "#ffb800",
                                        "title": {
                                        "text": "#fff",
                                        "background": "black"
                                        }
                                    }} 

                                />}
                    
                    <hr/>
                    
    
                </div>
            </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span> IA - Intelligences Artificielles</div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Chat GPT, utilise pour coder </div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 55
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 
    
                                    />}
                        
                        <hr/>
    
                        <div className="subtitle">Midjourney, génère des images Artificielles</div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Expert",
                                            "level": 100
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 
    
                                    />}
                        
                        <hr/>
    
                        
                        
        
                    </div>
                </>
                },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span>  Webdesign - Infographie</div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Webdesign</div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 80
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Industries Graphiques (Pré-presse) </div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 80
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Opérateur PAO, Infographiste </div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 80
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Illustrator, Photoshop, Indesign, QuarkXpress </div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 80
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Photographies, développements Argentiques, reproductions, numérisations </div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Opérationnel",
                                            "level": 80
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Modélisation 3D (3ds max, Blender)</div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Les bases",
                                            "level": 45
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        <div className="subtitle">Montage video (Première)</div>       
                                    {<SkillBar 
                                        skills={[
                                        {
                                            "type": "Les bases",
                                            "level": 45
                                        }
                                        ]} 
                                        height={20} 
                                        animationDelay={1000} 
                                        animationDuration={1000}
                                        colors={{
                                            "bar": "#ffb800",
                                            "title": {
                                            "text": "#fff",
                                            "background": "black"
                                            }
                                        }} 

                                    />}
                        
                        <hr/>

                        
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span>  App Mobiles</div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Flutter, Dart, Firebase</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Les bases",
                                                "level": 50
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                    
                    
                        
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span>  CMS (Content Management System)</div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Drupal</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Les bases",
                                                "level": 50
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Wordpress</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Les bases",
                                                "level": 50
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Magento</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "testé 2-3X",
                                                "level": 35
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Prestashop</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "testé 2-3X",
                                                "level": 35
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                    
                    
                        
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span> Autres Logiciels Informatiques </div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">VS code</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Opérationnel",
                                                "level": 70
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Netbeans </div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Apprenant",
                                                "level": 45
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Intellij community </div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Apprenant",
                                                "level": 45
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Spring Tool Suite </div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Apprenant",
                                                "level": 45
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Postman, Insomnia </div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Opérationnel",
                                                "level": 55
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Puppeteer, web scraping </div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Apprenant",
                                                "level": 45
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                        
                            <div className="subtitle">Suite Office</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Opérationnel",
                                                "level": 70
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                    
                    
                        
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span> Langues étrangères </div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">Anglais, Anglais technique, I manage</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Opérationnel",
                                                "level": 55
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Allemand (Niveau scolaire)</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Scolaire",
                                                "level": 40
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                        
                
                    
                    
                        
        
                    </div>
                </>
            },
            {
                title: 
                <>
                    <div className="title"> 
                    <span className="period"> {'>'}</span> Loisirs, centres d'intérets </div></>,
                content: 
                <>
                    <div className="contentFormation">
                        <div className="subtitle">HandBall (Ancien joueur à haut niveau, semi pro. Ancien Entraineur/Coach Equipe jeune)</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Expert",
                                                "level": 100
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Taekwondo, Hapkido, Ho Shin Soul, Self Défence</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Intermédiaire",
                                                "level": 55
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Tennis de table, Badminton</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Intermédiaire",
                                                "level": 55
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                        
                            <div className="subtitle">Magie/Close Up, il est où le lapin ?</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Avancé",
                                                "level": 80
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Crypto Monnaies, Blockchain, Smart Contract, Trading , Stacking, Hodl</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Intermédiaire",
                                                "level": 60
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Cinéma, Effets Spéciaux</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Passionné",
                                                "level": 100
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>

                            <div className="subtitle">Domotique, Iot</div>       
                                        {<SkillBar 
                                            skills={[
                                            {
                                                "type": "Maison",
                                                "level": 60
                                            }
                                            ]} 
                                            height={20} 
                                            animationDelay={1000} 
                                            animationDuration={1000}
                                            colors={{
                                                "bar": "#ffb800",
                                                "title": {
                                                "text": "#fff",
                                                "background": "black"
                                                }
                                            }} 

                                        />}
                            
                            <hr/>
                    
                    
                        
        
                    </div>
                </>
            },
            
            
            
    
        
        
        // {
        // title: 'test',
        // content: <><div className="contentFormation"> <Link to={'/accueil'} className="linkFormation">toto</Link> toto <br/> - tata</div> <div>top</div></>
        // },
        
];