import React, { useState, forwardRef} from 'react'
import {gsap} from "gsap"
import { Link } from 'react-router-dom'


import './letter.css'

const Letter = forwardRef( ({contentLetter},ref ) => {

    const [letter, setLetter] = useState (contentLetter)

    const onEnter = ({ currentTarget }) => {
        gsap.to(currentTarget, {rotation: "+=720", color:"white"});
    };
    
    const onLeave = ({ currentTarget }) => {
        gsap.to(currentTarget, { backgroundColor: "#000000", color: "orange" });
    };

    return (
        <div className='letter-container'>
            <Link to='/accueil' style={{paddingLeft: 13, textDecoration: 'none'}} ><div className='letter' ref={ref} onMouseEnter={onEnter} onMouseLeave={onLeave}>{letter}</div></Link>
        </div>
    )
})

export default Letter